@tailwind base;
@tailwind components;
@tailwind utilities;

.layout {
  @apply px-6 sm:px-10 md:px-20 lg:px-[10%];
}
.result-modal {
  background: url("./assets/bg/Group 1160444248.png") no-repeat top -200px left;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #555555;
}

.prevent-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.choice-card {
  img {
    max-height: 150px;
  }
}

.question-card {
  /* max-height: 500px; */
  img {
    max-height: 300px;
  }
}
